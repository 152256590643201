import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import store from './store'
import vueCookies from './plugins/vue-cookies';
import vueMeta from './plugins/vue-meta';
import prototype from './plugins/prototype';

Vue.prototype.$http = axios;
Vue.config.productionTip = false

if(process.env.NODE_ENV == "production" && location.protocol != "https:") location.protocol = "https:";

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
